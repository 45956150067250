@import '@ingka/variables/style';
.container{
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 2rem;
  p{
    margin-top: 0;
  }
  .chart{
    display: flex;
    .lineChart{
      background-color: $colour-static-light-grey;
      border-radius: 4px;
      margin-right: 0.6rem;
    }
  }
  
}