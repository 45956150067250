@import '@ingka/variables/style';
.badge{
    width: fit-content;
    padding: 0.2rem 0.7rem;
    font-size: $font-size-50;
    color: $colour-static-white;
    border-radius: 0.2rem;
    font-weight: $font-weight-bold;
}

.red{
    background-color: $colour-commercial-message-new-lower-price;
}
.green{
    background-color: $colour-semantic-positive;
}
.blue{
    background-color: $colour-static-ikea-brand-blue;
}
.yellow{
    background-color: $colour-static-ikea-brand-yellow;
}
.grey{
    background-color: $colour-static-grey;
}
.black{
    background-color: $colour-static-black;
}