@import '@ingka/variables/style';
.messageTitle{
    margin-top: 0;
}

.container{
    max-width: 37.5rem;
    margin: 0 auto;
    
    .stepTitle{
        display: flex;
        align-items: center;
        margin-top: 4rem;
        span{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 2rem;
            min-width: 2rem;
            height: 2rem;
            border: 2px solid;
            border-radius: 100%;
            margin-right: 1rem;
        }
    }
    .buttonDown{
        position: sticky;
        bottom: 2rem;
        left: 50%;
        transform: translate(-50%, 0);
        svg{
            animation: pulse 1s infinite;
        }
        
        
        
    }
    .submitButton{
        width: 100%;
        span{
            background-color: $colour-neutral-3;
            color: $colour-static-black;
        }
        &:active, &:hover{
            span{
                background-color: $colour-neutral-3;
                color: $colour-static-black;
            }
        }
    }
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
		opacity: 0.4;
	}

	70% {
		transform: scale(1);
		opacity: 1;
	}

	100% {
		transform: scale(0.95);
		opacity: 0.4;
	}
}