@import '@ingka/variables/style';

.header{
    background-color: $colour-static-ikea-brand-blue;
    display: flex;
    align-items: center;

    .left{
        display: flex;
        align-items: center;
        .title{
            text-decoration: none;
            span:nth-child(1){
                color: $colour-static-ikea-brand-yellow;
            }
            span:nth-child(2){
                color: $colour-static-white;
            }
        }
    
        .ham_btn{
            color: $colour-static-white;
        }
    }

    .right{
        margin-left: auto;
        .new_action{
            color: $colour-static-white;
        }
    }

    .side_menu{
        position: fixed;
        left: 0;
        top: 0;
        z-index: 8000;
        display: flex;
        flex-direction: column;
        background-color: rgb(var(--colour-elevation-2, 255, 255, 255));
        overflow-y: auto;
        overflow-x: hidden;
        transform: translateX(-470px);
        transition: transform 300ms ease-out;
        transition-property: transform, visibility;
        width: 100%;
        height: auto;
        visibility: hidden;
        min-height: 100%;
        padding: 1rem;

        @media screen and (min-width: $breakpoint-l) {
            width: 30rem;
            right: auto;
            padding: 1.5rem;
        }

        &.open{
            transform: translateX(0);
            visibility: visible;
        }
    }

    .overlay{
        @media screen and (min-width: $breakpoint-l) {
            display: block;
            position: fixed;
            z-index: 7999;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            background-color: $colour-static-black;
            opacity: 0;
            transition: all .2s ease;
            pointer-events: none;

            &.open{
                opacity: 0.25;
            }
        }
    }

    .side_menu_header{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: .5rem;
        border-bottom: 2px solid $colour-neutral-3;
        .title{
            span:nth-child(1){
                color: $colour-static-ikea-brand-yellow;
            }
            span:nth-child(2){
                color: $colour-static-black;
            }
        }
    }
    .side_menu_body{
        width: 100%;
        padding-top: 1.5rem;
    }
    .side_menu_footer{
        width: 100%;
        margin-top: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .logout{
            font-size: 1rem;
            font-weight: inherit;
        }
    }
}

.floatButton{
    position: fixed;
    right: 1rem;
    bottom: 2rem;
    z-index: 99;
    .new_action{
            color: $colour-static-white;
        }
}