@import '@ingka/variables/style';
.formField{
    margin-bottom: 0;
}

.select{
    span{
        border-radius: 40px;
        border: 1px solid $colour-static-black;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: block;
        width: 100%;
        pointer-events: none;
    }

    select{
        
        font-family: var(--ikea-font) !important;
        padding-inline-end: 0;
        padding: 0.5rem 3rem 0.5rem 1rem;
        height: auto;
        &:focus{
            outline: none;
            border: none;
        }
    }
    
}