@import '@ingka/variables/style';

.article{
    width: 100%;
    margin: 2rem auto;
    .header{
        display: flex;
        justify-content: space-between;
        align-items: end;
        .ref{
            background-color: $colour-static-grey;
            padding: 0.2rem 0.8rem;
            height: fit-content;
            font-size: 0.875rem;
            line-height: 1.571;
        }
        
    }
    .body{
        display: flex;
        h3, p{
            margin: 0;
        }
        .content{
            width: 65%;
            
        }
        .image{
            width: 35%;
            padding: 0.6rem;
            img{
                width: 100%;
                height: auto;
            }
        }
        
    }
    .data{
        display: flex;
        justify-content: space-between;
        padding-bottom: 0.6rem;
        div{
            display: flex;
            flex-direction: column;
            p{
                margin: 0;
            }
            
        }
        .ball{
            width: 0.6rem;
            height: 0.6rem;
            display: inline-block;
            border-radius: 100%;
            margin-right: 0.4rem;
            &.green{
                background-color: $colour-semantic-positive;
            }
            &.red{
                background-color: $colour-commercial-message-new;
            }
            &.yellow{
                background-color: $colour-static-ikea-brand-yellow;
            }
            &.Gray{
                background-color: $colour-static-dark-grey;
            }
        }
    }
    .accordion{
        button {
            border-bottom: none !important;
            padding: 0;
            min-height: 1.5rem;
        }
    }
}
