@import '@ingka/variables/style';

html{
    scroll-behavior: smooth;
}

body{
    margin: 0;
}

*{
    box-sizing: border-box;
}


.container-header, .container{
    width: 100%;
    margin: 0 auto;

    @media(min-width: 767px){
        padding: 0 1.5rem;
        max-width: 1300px;
    }
    @media(max-width: 767px){
        padding: 0 1rem;
    }
}

.container{
    margin: 0 auto 3rem auto;
}
.container-header{
    display: flex;
    align-items: center;
}

hr{
    border-block-start: $thickness-thin solid $colour-neutral-3;
}
/* TEXT */
.text-center{
    text-align: center;
}
.text-red{
    color: $colour-commercial-message-bti-red;
}

/*ROWS AND COLUMNS*/
.row{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin-top: calc(-1 * 0);
    margin-right: calc(-.5 * 1.5rem);
    margin-left: calc(-.5 * 1.5rem);
    align-items: end;
    &>*{
        padding-right: calc(1.5rem * .5);
        padding-left: calc(1.5rem * .5);
        margin-top: 0;
    }
}
.col{
    flex: 1 0 0%;
}
.col-12{
    flex: 0 0 auto;
    width: 100%;
}
.col-6{
    flex: 0 0 auto;
    width: 50%;
}
.col-4{
    flex: 0 0 auto;
    width: 33,33%;
}
.col-3{
    flex: 0 0 auto;
    width: 20%;
}
.col-2{
    flex: 0 0 auto;
    width: 16,66%;
}

/* MARGINGS */
.m0{
    margin: 0 !important;
}
.m1{
    margin: 1rem !important;
}
.my-1{
    margin: 1rem 0 !important;
}
.mt-05{
    margin-top: 0.5rem;
}
.mt-1{
    margin-top: 1rem;
}
.mt-2{
    margin-top: 2rem;
}
.mt-3{
    margin-top: 3rem;
}
.mt-4{
    margin-top: 4rem;
}
.mt-5{
    margin-top: 5rem;
}
.ml-1{
    margin-left: 1rem;
}
.ml-2{
    margin-left: 2rem;
}
.ml-3{
    margin-left: 3rem;
}
.pt-1{
    padding-top: 1rem;
}
.pt-2{
    padding-top: 2rem;
}
.pt-3{
    padding-top: 3rem;
}
.mb-1{
    margin-bottom: 1rem;
}
.mb-2{
    margin-bottom: 2rem;
}
.mb-3{
    margin-bottom: 3rem;
}

/* MESSAGE CARD */
.inline-message .inline-message__actions .goToButton{
    position: absolute;
    top: 0.6em;
    right: 0;
}
.inline-message .inline-message__content{
    padding-inline-end: 0rem; 
}


/* TOAST */
.toastsContainer{
    position: fixed;
    right: 2%;
    top: 2%;
    z-index: 9999;
    .inline-message{
        margin-bottom: 1rem;
    }
}

/* TABLE ROWS*/
.table{
    .MuiButtonBase-root{
        &:not(.Mui-active){
            svg{
                opacity: 1;
                color: rgba(0, 0, 0, 0.3);
                transform: rotate(-90deg);
            }
        }
    }
}

/* TABLE FLOW COLS*/
.table-flow{
    th{
        .Mui-TableHeadCell-Content-Wrapper{
            text-wrap: wrap;
        }
    }
}

/* FILTER MODAL ACCORDION BORDER TOP TO BOTTOM*/
.accordion .accordion-item-header {
    border-top: none !important;
    border-bottom: 1px solid rgb(var(--colour-neutral-3, 223, 223, 223));
}

/* CAROUSEL BUTTONS */
.overflow-carousel__button--right{
    right: 0;
    .btn__inner{
        padding: 0 0.45rem !important;
        min-height: 2.4rem !important;
    }
}
#overflow-carousel__demo-prev-btn, #overflow-carousel__default-prev-btn{
    left: 0;
    .btn__inner{
        padding: 0 0.45rem !important;
        min-height: 2.4rem !important;
    }
}

/* MODAL THEATRE*/
.theatre__content-wrapper{
    width: 100%;
    height: 100%;
    padding-bottom: 2rem;
}