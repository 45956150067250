@import '@ingka/variables/style';
.containerFilters{
    padding-top: 1rem;
    margin-top: 1rem;
    position: sticky;
    z-index: 101;
    top: 0;
    margin-left: -1rem;
    background-color: $colour-static-white;
    width: 100vw;
    max-width: 1300px;

    .filterButtons{
        @media(min-width: 767px){
            padding: 0 1.5rem;
            max-width: 1300px;
        }
        @media(max-width: 767px){
            padding: 0 1rem;
        }
    
        .filterButton{
            background-color: $colour-neutral-3;
            margin-left: 0.6rem;
        }
        
    }
    
}
.modal_header{
    border-bottom: $thickness-thin solid $colour-neutral-3;
}
.radioState{
    fieldset{
        display: flex;
        > span{
            margin-right: 1.5rem;
            margin-bottom: 0;
            label{
                padding-inline-start: 0.3rem;
            }
        }
        
    }
}