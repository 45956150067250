@import '@ingka/variables/style';

.upload{
    margin: 1rem 0 2rem 0;
    display: flex;
    flex-wrap: wrap;
    .box{
        width: 100px;
        height: 100px;
        border: 1px solid $colour-static-black;
        border-radius: 0.4rem;
        margin-right: 1rem;
        margin-bottom: 1rem;
        position: relative;
        background-position: center !important;
        background-size: cover !important;
        .deleteFile{
            position: absolute;
            bottom: 0;
            right: 0;
            background-color: rgb(255,255,255,0.7);
            span{
                min-height: 1rem;
                padding: 0.1rem;
            }
        }
    }
    .addButton{
        height: fit-content;
        span{
            background-color: $colour-static-grey;
        }
        
    }
}
.modalBody{
    text-align: center;
    .modeUploadButton{
        margin-bottom: 2rem;
    }
    .loadingBack{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
    }
}
