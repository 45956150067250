@import '@ingka/variables/style';
.header{
    display: flex;
    justify-content: space-between;
    .info{
        display: flex;
        flex-direction: column;
        align-items: end;
        justify-content: space-between;
        margin-left: 0.6rem;
        min-width: 80px;
    }
    .title{
        margin: 0;
        text-transform: capitalize;
    }
    .flow{
        margin-top: 0.5rem;
        margin-bottom: 0;
    }
}

.body{
    p{
        margin: 0;
    }
}

.footer{
    margin-top: 1rem;
}

.image_container{
    margin-top: 0.5rem;
    display: flex;
    flex-wrap: wrap;
    .box{
        width: 60px;
        height: 60px;
        border: 1px solid $colour-static-black;
        border-radius: 0.4rem;
        margin-right: 0.5rem;
        margin-top: 0.5rem;
        position: relative;
        background-position: center !important;
        background-size: cover !important;
        cursor: pointer;
    }
}